<template>
    <main class="my-leads">
		
		<section class="content-my-leads">
			<div class="content-my-leads-itens">
				<div class="content-my-leads-itens-bg">
					<div class="container">
						<div class="content-my-leads-itens-header">
							<div class="content-my-leads-itens-header-div">
								<router-link class="btn-return" to="/">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582"><path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z" transform="translate(5.256 12.867)"/></svg>
									<p>voltar</p>
								</router-link>
								<h1 class="title">
									Os seus gestores
								</h1>
							</div>
						</div>
					</div>
				</div>
				<div class="content-my-leads-itens-body">
					<div class="container">
						<div class="content-table">
							<table class="content-table-element">
								<tr>
									<th>Nome</th>
									<th>Email</th>
									<th>Leads Submetidas/Aprovadas</th>
									<th>Estado</th>
								</tr>			
								<template v-if="Object.keys(users).length">
									<tr v-for="user in users" :key="user.id">
										<td>{{ user.name }}</td>
										<td>{{ user.email }}</td>
										<td>{{ user.leads_submited }}/{{ user.leads_approved }}</td>
										<td :style="{color: user.is_approved ? '#7CC16F' : '#EE8282'}" style="font-weight: bold;">{{ user.is_approved ? 'REGISTADO' : 'INATIVO' }}</td>
									</tr>
								</template>	
							</table>
							<!-- pagination  -->								
							<div v-if="Object.keys(users).length && users.last_page > 1" class="col-sm-12 mt-5" align="center">
								<nav aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item">
											<a class="page-link"
											  :class="{
												  isDisabled: (!(users.current_page > 1))
											  }"
											  @click="setPage(users.current_page - 1)">Voltar</a>											  
										</li>

										<template v-for="page in users.last_page">
											<li class="page-item" :key="page"
											:class="{active: (users.current_page == page)}">
												<a class="page-link" @click="setPage(page)">{{page}}</a>
											</li>
										</template>										
										
										<li class="page-item">
											<a class="page-link"
											  :class="{
												  isDisabled: ((users.current_page == users.last_page))
											  }" 
											  @click="setPage(users.current_page + 1)">Avançar</a>
										</li>
									</ul>
								</nav>
							</div>
							<!--  -->
						</div>						
					</div>
				</div>
			</div>
		</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            users : {}, 
			page: 1,           
        }
    },    
	computed: {
		...mapGetters('User', ['user']),
	},
    methods:{
        ...mapActions('App', ['loading']),
		async setPage(page){
			this.page = page;
			await this.getList();
		},
        async getList(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/my-managers',
                params: {
                   page: this.page,
				   user_id: this.user.id
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false); 				   	
               this.users = data;              
            })          
        },        
    },
    async mounted(){        		
        await this.getList();
    },
	async created(){
		this.$store.dispatch('App/loading', false);
		await this.getList();
	}
}
</script>
<style scoped>
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.content-table-element tr td a{
	color: #707070;
	font-size: 18px;
	font-family: 'Font Regular';
}

</style>